import { b64toBlob } from "@/utils/claim/b64toBlob";

// const useView = () => {
//   const handleImgArr = (arr: any) => {
//     return arr.map((ele: any) => {
//       if (ele.base64) {
//         return {
//           desType: ele.desType,
//           downUrl: ele.downloadUrl,
//           imgUrl: URL.createObjectURL(b64toBlob(ele.base64, "application/pdf")),
//           docType: false,
//         };
//       } else {
//         return {
//           desType: ele.desType,
//           downUrl: ele.downloadUrl,
//           imgUrl: ele.downloadUrl,
//           docType: true,
//         };
//       }
//     });
//   };

//   return handleImgArr;
// };

// export default useView;

export const handleImgArr = (arr: any) => {
  return arr.map((ele: any) => {
    if (ele.base64) {
      return {
        desType: ele.docDesc,
        downloadUrl: ele.docUrl,
        url: URL.createObjectURL(b64toBlob(ele.base64, "application/pdf")),
        docType: ele.docType,
        base64code: ele.base64code,
        docId: ele.docId,
      };
    } else {
      return {
        desType: ele.docDesc,
        downloadUrl: ele.docUrl,
        url: ele.docUrl,
        docType: ele.docType,
        base64code: ele.base64code,
        docId: ele.docId,
      };
    }
  });
};
