const cpoColumns = [
  {
    title: "Request Code",
    width: 150,
    dataIndex: "requestCode",
  },
  {
    title: "Request Type",
    width: 120,
    dataIndex: "requestType",
  },
  {
    title: "Regions",
    width: 150,
    dataIndex: "regions",
  },
  {
    title: "Dealer Code",
    width: 120,
    dataIndex: "dealerCode",
  },
  {
    title: "Dealer Name",
    width: 200,
    dataIndex: "dealerName",
  },
  {
    title: "Seller",
    width: 200,
    dataIndex: "seller",
  },
  {
    title: "ID NO./Legal Entity Code Of Seller",
    width: 120,
    dataIndex: "idNo",
  },
  {
    title: "Certification Date",
    width: 120,
    dataIndex: "certificationDate",
  },
  {
    title: "UC VIN",
    width: 100,
    dataIndex: "vinNo",
  },
  {
    title: "Series",
    width: 100,
    dataIndex: "series",
  },
  {
    title: "Model",
    width: 100,
    dataIndex: "model",
  },
  {
    title: "1st Registration Date",
    width: 200,
    dataIndex: "firstRegistDate",
  },
  {
    title: "New Car Warranty Start Date",
    width: 200,
    dataIndex: "startRepairDate",
  },
  {
    title: "Buyer",
    width: 100,
    dataIndex: "buyer",
  },
  {
    title: "Warranty Number",
    width: 150,
    dataIndex: "repairCode",
  },
  {
    title: "Certificate No.",
    width: 150,
    dataIndex: "certificateCode",
  },
  {
    title: "Title Transfer Type",
    width: 150,
    dataIndex: "transferType",
  },
  {
    title: "UC Invoice Date",
    width: 150,
    dataIndex: "invoiceDate",
  },
  {
    title: "UC Title Transfer Date",
    width: 150,
    dataIndex: "transferDate",
  },

  {
    title: "Request Date",
    width: 120,
    dataIndex: "requestDate",
  },
  {
    title: "Status",
    dataIndex: "statusName",
    width: 150,
  },
  {
    title: "Operation",
    width: 100,
    slots: { customRender: "operation" },
  },
];

const tradeInColumns = [
  {
    title: "Request Code",
    width: 150,
    dataIndex: "requestCode",
  },
  {
    title: "Request Type",
    width: 120,
    dataIndex: "requestType",
  },
  {
    title: "Regions",
    width: 150,
    dataIndex: "regions",
  },
  {
    title: "Dealer Code",
    width: 120,
    dataIndex: "dealerCode",
  },
  {
    title: "Dealer Name",
    width: 200,
    dataIndex: "dealerName",
  },
  {
    title: "Series",
    width: 100,
    dataIndex: "series",
  },
  {
    title: "Model",
    width: 100,
    dataIndex: "model",
  },
  {
    title: "UC VIN",
    width: 100,
    dataIndex: "ucVinNo",
  },
  {
    title: "1st Registration Date",
    width: 200,
    dataIndex: "firstRegistDate",
  },
  {
    title: "UC Title Transfer Date",
    width: 150,
    dataIndex: "transferDate",
  },
  {
    title: "VIN No.",
    width: 150,
    dataIndex: "vinNo",
  },
  {
    title: "New Car Inovice Date",
    width: 120,
    dataIndex: "newInvoiceDate",
  },
  {
    title: "Customer Name",
    width: 120,
    dataIndex: "customerName",
  },

  {
    title: "NC Buyer Customer Name",
    width: 120,
    dataIndex: "newCustomerName",
  },
  {
    title: "Request Date",
    width: 120,
    dataIndex: "requestDate",
  },
  {
    title: "Status",
    dataIndex: "statusName",
    width: 150,
  },
  {
    title: "Operation",
    width: 100,
    slots: { customRender: "operation" },
  },
];

export { cpoColumns, tradeInColumns };
