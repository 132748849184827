import { computed, reactive, ref, onMounted } from "vue";
import { cpoColumns, tradeInColumns } from "./columns";
import { StatusListMap2, RequestTypeListEnum } from "@/views/Delay/type";
import fixTable from "@/hooks/checking/useFixTable";
import { message } from "ant-design-vue";
// import { downloadFromStream } from "@/utils/payment/downloadFile";
import { handleImgArr } from "@/hooks/claimDelay/useView";
import downloadFile from "@/utils/claim/downloadFile";

type InputParams = {
  getOverviewValue: (type: number) => void;
  fetchTableData: any;
  reset: () => void;
};

const useUcTable = ({
  getOverviewValue,
  fetchTableData,
  reset,
}: InputParams): any => {
  const tableData = ref<any[]>([]);
  const tabKey = ref(1);
  const changeTab = (key: number) => {
    tabKey.value = key;
    reset();
    tableData.value = [];
    getOverviewValue(tabKey.value);
  };
  const isCPO = computed(() => {
    return tabKey.value === 1;
  });

  const pagination = reactive({
    total: 0,
    currentPage: 1,
    pageSize: 20,
  });
  const state = reactive({
    selectedRowKeys: [],
    selectedRows: [],
  });
  const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    state.selectedRowKeys = selectedRowKeys;
    state.selectedRows = selectedRows;
  };
  const mapRequestType = (text: string): string => {
    return RequestTypeListEnum[text];
  };
  const mapStatus = (text: string): string => {
    return StatusListMap2[text];
  };

  const columns = computed(() => {
    return isCPO.value ? cpoColumns : tradeInColumns;
  });
  const height: number =
    50 + 8 + 80 + 8 + 133 + 10 + 8 + 10 + 32 + 10 + 5 + 48 + 135 + 50;
  const tableHeight = fixTable(height);

  const getTableData = () => {
    onSelectChange([], []);
    const otherParams = {
      current: pagination.currentPage,
      size: pagination.pageSize,
      applyType: tabKey.value,
    };
    const type = otherParams.applyType === 1 ? "cpo" : "trade";
    fetchTableData(otherParams, type).then((res: any) => {
      tableData.value = res.delayInfoList;
      pagination.total = res.totalElements;
    });
  };

  const pageChange = (page: number) => {
    pagination.currentPage = page;
    getTableData();
  };
  const sizeChange = (page: number, pageSize: number) => {
    pagination.pageSize = pageSize;
    pagination.currentPage = page;
    getTableData();
  };

  const search = () => {
    pagination.currentPage = 1;
    getTableData();
  };

  const isSelectedRowsAllSubmitted = (status: string) => {
    const statusList = state.selectedRows.map((item: any) => item.status);
    return statusList.every((curStatus: string) => curStatus === status);
  };

  const checkCondition = (status: string) => {
    if (state.selectedRowKeys.length === 0) {
      message.warning("Please select at least one application");
      return true;
    }
    if (!isSelectedRowsAllSubmitted(status)) {
      message.warning("The application in this status can’t be operated");
      return true;
    }
    return false;
  };

  const approveHandler = async (
    approveAPI: (params: any) => Promise<any>,
    params: any,
    msg: string,
    status: string
  ) => {
    if (checkCondition(status)) return;
    const res = await approveAPI(params);
    message.success(msg);
    search();
    getOverviewValue(tabKey.value);
  };

  const rejectModalVisible = ref(false);
  const openRejectModal = (status: string) => {
    if (checkCondition(status)) return;
    rejectModalVisible.value = true;
  };
  const rejectHandler = async (
    rejectAPI: (params: any) => Promise<any>,
    params: any,
    msg: string
  ) => {
    const res = await rejectAPI(params);
    message.success(msg);
    rejectModalVisible.value = false;
    search();
    getOverviewValue(tabKey.value);
  };

  const handleExport = (curretParams: any) => {
    const url = isCPO.value
      ? "/rvapi/uc/region/delay/cpo/export"
      : "/rvapi/uc/region/delay/trade/export";
    const params = {
      url,
      method: "post",
      params: {},
    };
    if (state.selectedRowKeys.length) {
      params.params = { idList: state.selectedRowKeys };
    } else {
      params.params = { ...curretParams, applyType: tabKey.value };
    }
    downloadFile(params, "application/vnd-excel; char-set=UTF-8");
  };

  const viewModelVisible = ref(false);
  const viewList = ref<any>([]);
  const viewHandler = (viewAPI: (params: any) => Promise<any>, ucId: any) => {
    viewModelVisible.value = true;
    viewAPI({ type: tabKey.value, ucId }).then((res: any) => {
      if (res.length) {
        viewList.value = handleImgArr(res);
      }
    });
  };

  onMounted(() => {
    getOverviewValue(tabKey.value);
  });

  return {
    tabKey,
    changeTab,
    isCPO,
    pagination,
    state,
    onSelectChange,
    mapRequestType,
    mapStatus,
    columns,
    tableHeight,
    pageChange,
    sizeChange,
    tableData,
    getTableData,
    search,
    approveHandler,
    rejectModalVisible,
    openRejectModal,
    rejectHandler,
    handleExport,
    viewModelVisible,
    viewList,
    viewHandler,
  };
};

export default useUcTable;
