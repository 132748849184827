
import { defineComponent, PropType } from "vue";
import { downloadFromUrl } from "@/utils/payment/downloadFile";
import { downloadViewFile } from "@/API/claimDelay/delayUC";
export default defineComponent({
  emits: ["update:visible", "close-view-visible", "init-table"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    viewList: {
      type: Array as PropType<any>,
      default: () => [],
      required: false,
    },
  },
  setup(props, { emit }) {
    const cancel = () => {
      emit("update:visible", false);
    };
    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );
    const download = (docId: any) => {
      downloadViewFile(docId).then((res: any) => {
        downloadFromUrl(res.downloadUrl);
      });
    };
    return {
      cancel,
      spinWrapper,
      downloadFromUrl,
      download,
    };
  },
});
