import { fetchGet, fetchPost, fetchPut } from "../index";

export const getOverviewValueOfRegion = (params: any): Promise<any> => {
  return fetchGet("/rvapi/uc/region/delay/count", { params });
};

export const getOverviewValueOfSipo = (params: any): Promise<any> => {
  return fetchGet("/rvapi/uc/sipo/delay/count", { params });
};

export const getPreviewList = ({
  type,
  ucId,
}: {
  type: number;
  ucId: string;
}): Promise<any> => {
  return fetchGet(`/rvapi/uc/region/delay/preview/${type}/${ucId}`);
};

export const searchData = (
  params: any,
  type: "cpo" | "trade"
): Promise<any> => {
  return fetchPost(`/rvapi/uc/region/delay/${type}/page`, params);
  // return Promise.resolve({
  //   delayInfoList: [
  //     { requestCode: "123", requestType: "456", status: "1", ucId: 441, id: 1 },
  //     { requestCode: "123", requestType: "456", status: "2", ucId: 442, id: 2 },
  //   ],
  // });
};

export const approveRegion = (params: any): Promise<any> => {
  return fetchPut("/rvapi/uc/region/delay/approve", params);
};

export const approveSipo = (params: any): Promise<any> => {
  return fetchPut("/rvapi/uc/sipo/delay/approve", params);
};

export const rejectRegion = (params: any): Promise<any> => {
  return fetchPut("/rvapi/uc/region/delay/reject", params);
};

export const rejectSipo = (params: any): Promise<any> => {
  return fetchPut("/rvapi/uc/sipo/delay/reject", params);
};

export const downloadViewFile = (documentId: string): Promise<any> => {
  return fetchGet(`docapi/download/stream/${documentId}`);
};
